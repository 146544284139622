<template>
  <div class="vestManagement">
    <!-- 马甲查询/新增 马甲管理 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="马甲名称">
        <el-input size="small" clearable v-model="formInline.name" placeholder="请输入要查询的马甲名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" @click="onSubmit" type="primary">查询</el-button>
        <el-button v-if="role_account_vest_add" size="mini" @click="add" type="success">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 马甲表格 -->
    <div class="roleTable">
      <div class="head">
        <!-- 按钮选项 -->
        <div class="btn">
          <el-button size="mini" :class="btnCur == index ? 'active' : ''" v-for="(item, index) in btn" :key="index"
            @click="btnFn(index)">{{ item.name }}</el-button>
        </div>
        <!-- 分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-size="count" :page-sizes="[15, 30, 45, 60]" layout="total, sizes, prev, pager, next"
          :total="total"></el-pagination>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="horseName" label="马甲名称" align="center"></el-table-column>
        <el-table-column prop="platformId" label="渠道" align="center">
          <template slot-scope="scope">
            <div>
              <!-- {{
      scope.row.platformId == '1' ? '艺龙' : scope.row.platformId == '2' ? '飞猪' : scope.row.platformId == '3' ?
        '美团' : scope.row.platformId == '4' ? '去哪儿' : scope.row.platformId == '5' ? '携程' : scope.row.platformId ==
          '6' ? '百度' : ''
    }} -->
              <span v-if="scope.row.platformId == '1'">艺龙</span>
              <span v-if="scope.row.platformId == '2'">飞猪</span>
              <span v-if="scope.row.platformId == '3'">美团</span>
              <span v-if="scope.row.platformId == '4'">去哪儿</span>
              <span v-if="scope.row.platformId == '5'">携程</span>
              <span v-if="scope.row.platformId == '6'">百度</span>
              <span v-if="scope.row.platformId == '7'">萝卜</span>
              <span v-if="scope.row.platformId == '8'">房仓天下</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium" v-if="scope.row.status == 1" type="success">在线</el-tag>
              <el-tag size="medium" v-if="scope.row.status == 0" type="danger">禁用</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="role_account_vest_edit" type="primary" @click="revise(scope.row)"
              size="mini">修改</el-button>
            <el-button v-if="role_account_vest_del" type="danger" @click="del(scope.row)" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加马甲弹出框 -->
    <Addvest :getVest="getVest" ref="addvest"></Addvest>
    <!-- 修改马甲弹出框 -->
    <Revisevest ref="revisevest" :getVest="getVest"></Revisevest>
  </div>
</template>

<script>
import Addvest from "./Addvest.vue";
import Revisevest from "./Revisevest.vue";
import { vest_list, delVest_api } from "../../../apis/acc";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: {
    Addvest,
    Revisevest,
  },
  data() {
    return {
      role_account_vest_add: getRoleKey("role_account_vest_add"),
      role_account_vest_edit: getRoleKey("role_account_vest_edit"),
      role_account_vest_del: getRoleKey("role_account_vest_del"),
      // 选项卡按钮
      btnCur: "0",
      // 角色查询新增
      formInline: {
        name: "",
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //   角色表格
      tableData: [],
      //选项按钮
      btn: [
        { name: "全部" },
        { name: "艺龙" },
        { name: "飞猪" },
        { name: "美团" },
        { name: "去哪儿" },
        { name: "携程" },
        { name: "百度" },
        { name: "萝卜" },
        { name: "房仓天下" },
      ],
      newArr: [],
    };
  },
  created() {
    this.getVest();
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
      //  console.log(index);
      this.btnCur = index;
      if (index == "0") {
        this.getVest();
      } else if (index == "1") {
        this.getVest(index, "");
      } else if (index == "2") {
        this.getVest(index, "");
      } else if (index == "3") {
        this.getVest(index, "");
      } else if (index == "4") {
        this.getVest(index, "");
      } else if (index == "5") {
        this.getVest(index, "");
      } else if (index == "6") {
        this.getVest(index, "");
      } else if (index == "7") {
        this.getVest(index, "");
      } else if (index == "8") {
        this.getVest(index, "");
      }
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getVest();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getVest();
    },
    //分页查询马甲列表
    async getVest(platform, name) {
      let { code, data } = await vest_list({
        page: this.currentPage,
        rows: this.count,
        // 1、艺龙 2、飞猪 3、美团 4、去哪儿 5、携程  6、百度 7、萝卜 8、房仓天下

        platform: platform,
        name: name,
      });
      if (code == 0) {
        //  console.log(data, "查询马甲列表");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    // ? "艺龙,飞猪,美团,去哪儿,携程"
    //马甲查询
    onSubmit() {
      this.getVest("", this.formInline.name);
    },
    //添加马甲
    add() {
      this.$refs.addvest.open();
    },
    //删除马甲
    async del(row) {
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dels(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除马甲功能函数
    async dels(row) {
      let { code, msg } = await delVest_api({
        ids: row.id,
      });
      if (code == 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getVest();
      }
    },
    //修改马甲
    revise(row) {
      // row 代表当前点击的数据
      this.$refs.revisevest.open(row);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner,
/deep/.el-range-input {
  background: #f6f8fb;
}

/deep/.has-gutter {
  background: #f6f8fb !important;
}

.vestManagement {
  /deep/.demo-form-inline {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);

    .el-form-item {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }

  .roleTable {
    width: 100%;
    // height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);

    .head {
      display: flex;
      justify-content: space-between;

      .btn {
        padding: 10px;
        box-sizing: border-box;

        button {
          border: 1px solid #256def;
          color: #256def;
        }

        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }

      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>